<template>
    <b-card v-if="role" :title="`${$t('edit_role')}: ${role.name}`">
        <form @submit.prevent="editRole" @keydown="form.onKeydown($event)" class="edit-form" enctype="multipart/form-data">
            <b-row>
                <b-col sm="6">
                    <div class="form-group">
                        <label for="name">{{ $t('name') }}<span class="text-danger">*</span></label>
                        <input type="text" name="name" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name" />
                    </div>
                </b-col>
                <b-col sm="6">
                    <b-card :title="$t('permissions')">
                        <div class="custom-control custom-checkbox" v-for="(item, index) in permissions" :key="index">
                            <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`customCheck_${index}`"
                            :value="item.id"
                            v-model="rolePermission"
                            @click="checkPermission($event)">
                            <label class="custom-control-label" :for="`customCheck_${index}`">{{ item.name }}</label>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('edit') }}</button>
        </form>
    </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'

export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('edit_role') }
    },
    data: () => ({
        form: new Form({
            name: ''
        }),
        rolePermission: [],
        permissionsChecked: [],
        permissions: null,
        role: null
    }),
    mounted () {
        this.getRole()
        this.getPermissions()
    },
    methods: {
        async editRole () {
            // add new user
            this.form.busy = true
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const id = this.$route.params.id
            this.form.permissions = this.permissionsChecked
            await this.form.put(apiUrl + 'roles/' + id).then(resp => {
                this.$router.push({ name: 'roles' })
            }).catch(error => {
                this.form.errors.set(error.response.data.error.errors)
            })
        },
        async getPermissions () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'permissions/list')
            const permissions = data.data
            this.permissions = permissions
        },
        async getRole () {
            const id = this.$route.params.id
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'roles/' + id)
            this.form.fill(data.data)
            data.data.permissions.data.forEach(p => {
                this.rolePermission.push(p.id)
                this.permissionsChecked.push(p.id)
            })
            this.role = data.data
        },
        checkPermission (e) {
            const checked = e.target.checked
            // checking checkbox checked
            if (checked) {
                // push to array
                this.permissionsChecked.push(e.target.value)
            } else {
                // remove from array
                const index = this.permissionsChecked.indexOf(e.target.value)
                if (index > -1) {
                    this.permissionsChecked.splice(index, 1)
                }
            }
        }
    }
}
</script>
